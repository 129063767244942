import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    SxProps,
    Theme,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ColorScheme, theme } from '../../constants/theme';
import { useThemeContext } from '../../hook/useThemeManager';
import { TransitionProps } from '@mui/material/transitions';
import { StyleSheet } from 'react-native';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { isEmpty } from '../../utils/lodash';
import { TProject } from './more.data';
import CloseIcon from '../CustomSVGIcons/CloseIcon';

interface IMore {
    onClick: (moreType: string) => void;
    menu: TProject[];
    sx?: SxProps<Theme>;
    typographyStyle?: React.CSSProperties;
    onIconClick?: () => void;
    onClose?: () => void;
    title?: string;
    alternateMenuMessage?: string;
    alternateAction?: () => void;
    alternateActionButtonText?: string;
    alternateSubText?: string;
    disableButton?: boolean;
    customMoreIcon?: JSX.Element;
    customIconSx?: SxProps<Theme>;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenMoreMenu: React.FC<IMore> = ({
    onClick,
    menu,
    sx,
    typographyStyle,
    onIconClick,
    onClose,
    title,
    alternateMenuMessage,
    alternateAction,
    alternateActionButtonText,
    alternateSubText,
    disableButton,
    customMoreIcon,
    customIconSx,
}) => {
    const { themeStyle } = useThemeContext();
    const themeui = useTheme();
    const fullScreen = useMediaQuery(themeui.breakpoints.down('md'));
    const styles = dynamicStyles(themeStyle);
    const [open, setOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(!open);
        onIconClick && onIconClick();
    };

    const handleClose = () => {
        setOpen(!open);
        onClose && onClose();
    };

    const handleOnClickHandle = (title: string) => {
        onClick && onClick(title);
        handleClose();
    };

    const renderMenu = (
        <Dialog
            scroll="paper"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            fullWidth={false}
            fullScreen={false}
            maxWidth={'lg'}
            PaperProps={{
                style: {
                    backgroundColor: fullScreen
                        ? theme[themeStyle].background_gradient.mid
                        : theme[themeStyle].background_gradient.start, // make this a color variable
                    width: '90vw',
                    maxWidth: '500px',
                    borderRadius: 20,
                    border: `0.5px solid ${theme[themeStyle].darkGray60}`,
                    backdropFilter: 'blur(0.5px)',
                    margin: 0,
                },
            }}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
                backgroundColor: theme[themeStyle].modal_dark,
            }}>
            <DialogTitle>
                <Box
                    sx={{
                        width: '100%',
                        height: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <Typography style={styles.title}>{title}</Typography>
                    <CloseIcon
                        onClick={handleClose}
                        style={{ cursor: 'pointer' }}
                        stroke={theme[themeStyle].tag_white}
                    />
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    style={{
                        padding: '8px',
                        minWidth: '150px',
                    }}>
                    {isEmpty(menu) && <Typography style={styles.button}>{alternateMenuMessage}</Typography>}
                    {alternateSubText && alternateAction && alternateActionButtonText && isEmpty(menu) && (
                        <Typography style={styles.subText}>{alternateSubText}</Typography>
                    )}
                    {alternateAction && alternateActionButtonText && isEmpty(menu) && (
                        <Button
                            onClick={() => {
                                alternateAction && alternateAction();
                                setOpen(!open);
                            }}
                            disabled={disableButton}
                            style={{
                                height: '52px',
                                borderRadius: 100,
                                fontSize: 16,
                                fontWeight: '600',
                                fontFamily: FONT_ENUM.MAIN_BOLD,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            sx={{
                                textDecoration: 'none',
                                backgroundColor: theme[themeStyle].deep_grey_lighter,
                                border: `2px solid ${theme[themeStyle].primary_orange}`,
                                color: theme[themeStyle].pure_black,
                                textTransform: 'none',
                                width: '100%',
                                '&:hover': {
                                    border: `2px solid ${theme[themeStyle].primary_orange}`,
                                    backgroundColor: theme[themeStyle].pure_black,
                                    color: theme[themeStyle].primary_orange,
                                },
                                my: 3,
                            }}>
                            <Typography style={styles.button}>{alternateActionButtonText}</Typography>
                        </Button>
                    )}
                    {!isEmpty(menu) &&
                        menu.map(
                            (d, i) =>
                                !!d &&
                                d.id &&
                                d.title && (
                                    <Button
                                        key={i}
                                        onClick={() => handleOnClickHandle(d.id)}
                                        style={{
                                            height: '52px',
                                            borderRadius: 100,
                                            fontSize: 16,
                                            fontWeight: '600',
                                            fontFamily: FONT_ENUM.MAIN_BOLD,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        sx={{
                                            textDecoration: 'none',
                                            backgroundColor: theme[themeStyle].deep_grey_lighter,
                                            border: `2px solid ${theme[themeStyle].primary_orange}`,
                                            color: theme[themeStyle].pure_black,
                                            textTransform: 'none',
                                            width: '100%',
                                            '&:hover': {
                                                border: `2px solid ${theme[themeStyle].primary_orange}`,
                                                backgroundColor: theme[themeStyle].pure_black,
                                                color: theme[themeStyle].primary_orange,
                                            },
                                            my: 3,
                                        }}>
                                        <Typography style={styles.button}>{d.title}</Typography>
                                    </Button>
                                ),
                        )}
                </Box>
            </DialogContent>
        </Dialog>
    );

    return (
        <>
            <IconButton onClick={handleClick} sx={customIconSx}>
                {customMoreIcon ?? (
                    <MoreVertIcon
                        sx={{
                            color: theme[themeStyle].tag_white,
                            ...sx,
                        }}
                    />
                )}
            </IconButton>
            {renderMenu}
        </>
    );
};

export { FullScreenMoreMenu };

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        close: {
            width: 'fit-content',
            height: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            position: 'absolute',
            right: '10px',
            top: '10px',
        },
        title: {
            textAlign: 'left',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: '600',
            color: theme[colorScheme].primary_white,
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textTransform: 'capitalize',
        },
        button: {
            fontFamily: FONT_ENUM.MEDIUM_HEADER,
            color: theme[colorScheme].primary_white,
        },
        subText: {
            fontFamily: FONT_ENUM.REGULAR_HEADER,
            color: theme[colorScheme].light_gray_text,
            fontSize: 12,
            marginTop: 10,
        },
    });
