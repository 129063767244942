import {
    IAdvancedContest,
    IContest,
    IContestAward,
    IContestPrize,
    IContestSubmissionsWithVotes,
    IProject,
    IReedemableCode,
    ISubmission,
    IUserSummary,
    TContestGenre,
    UserSummary,
} from '../../types/global/helper';
import { IProjectSummary } from '../../types/global/media.types';
import { IHost, IPartner } from '../discovery/types';
import { AwardStatus } from '../festival/types';

export const types = {
    GET_BOOKMARK: 'GET_BOOKMARK',
    GET_BOOKMARK_SUCCESS: 'GET_BOOKMARK_SUCCESS',
    GET_BOOKMARK_FAIL: 'GET_BOOKMARK_FAIL',

    DELETE_BOOKMARK: 'DELETE_BOOKMARK',
    DELETE_BOOKMARK_SUCCESS: 'DELETE_BOOKMARK_SUCCESS',
    DELETE_BOOKMARK_FAIL: 'DELETE_BOOKMARK_FAIL',

    CREATE_CONTEST: 'CREATE_CONTEST',
    CREATE_CONTEST_SUCCESS: 'CREATE_CONTEST_SUCCESS',
    CREATE_CONTEST_FAIL: 'CREATE_CONTEST_FAIL',

    UPDATE_CONTEST: 'UPDATE_CONTEST',
    UPDATE_CONTEST_SUCCESS: 'UPDATE_CONTEST_SUCCESS',
    UPDATE_CONTEST_FAIL: 'UPDATE_CONTEST_FAIL',

    GET_USER_CONTESTS: 'GET_USER_CONTESTS',
    GET_USER_CONTESTS_SUCCESS: 'GET_USER_CONTESTS_SUCCESS',
    GET_USER_CONTESTS_FAIL: 'GET_USER_CONTESTS_FAIL',

    GET_USER_CONTEST: 'GET_USER_CONTEST',
    GET_USER_CONTEST_SUCCESS: 'GET_USER_CONTEST_SUCCESS',
    GET_USER_CONTEST_FAIL: 'GET_USER_CONTEST_FAIL',

    GET_CONTESTS: 'GET_CONTESTS',
    GET_CONTESTS_SUCCESS: 'GET_CONTESTS_SUCCESS',
    GET_CONTESTS_FAIL: 'GET_CONTESTS_FAIL',

    GET_CONTEST: 'GET_CONTEST',
    GET_CONTEST_SUCCESS: 'GET_CONTEST_SUCCESS',
    GET_CONTEST_FAIL: 'GET_CONTEST_FAIL',

    GET_RECOMMENDATION: 'GET_RECOMMENDATION',
    GET_RECOMMENDATION_SUCCESS: 'GET_RECOMMENDATION_SUCCESS',
    GET_RECOMMENDATION_FAIL: 'GET_RECOMMENDATION_FAIL',

    DELETE_CONTEST: 'DELETE_CONTEST',
    DELETE_CONTEST_SUCCESS: 'DELETE_CONTEST_SUCCESS',
    DELETE_CONTEST_FAIL: 'DELETE_CONTEST_FAIL',

    PUBLISH_CONTEST: 'PUBLISH_CONTEST',
    PUBLISH_CONTEST_SUCCESS: 'PUBLISH_CONTEST_SUCCESS',
    PUBLISH_CONTEST_FAIL: 'PUBLISH_CONTEST_FAIL',

    UNPUBLISH_CONTEST: 'UNPUBLISH_CONTEST',
    UNPUBLISH_CONTEST_SUCCESS: 'UNPUBLISH_CONTEST_SUCCESS',
    UNPUBLISH_CONTEST_FAIL: 'UNPUBLISH_CONTEST_FAIL',

    SUBMIT_PROJECT: 'SUBMIT_PROJECT',
    SUBMIT_PROJECT_SUCCESS: 'SUBMIT_PROJECT_SUCCESS',
    SUBMIT_PROJECT_FAIL: 'SUBMIT_PROJECT_FAIL',

    GET_MY_SUBMISSIONS: 'GET_MY_SUBMISSIONS',
    GET_MY_SUBMISSIONS_SUCCESS: 'GET_MY_SUBMISSIONS_SUCCESS',
    GET_MY_SUBMISSIONS_FAIL: 'GET_MY_SUBMISSIONS_FAIL',

    RESCIND_SUBMISSION: 'RESCIND_SUBMISSION',
    RESCIND_SUBMISSION_SUCCESS: 'RESCIND_SUBMISSION_SUCCESS',
    RESCIND_SUBMISSION_FAIL: 'RESCIND_SUBMISSION_FAIL',

    SET_SUBMISSION_MODAL_STATE: 'SET_SUBMISSION_MODAL_STATE',
    SET_CONTEST_FOR_SUBMISSION: 'SET_CONTEST_FOR_SUBMISSION',
    RESET_CONTEST_DATA: 'RESET_CONTEST_DATA',

    VOTE_FOR_SUBMISSION: 'VOTE_FOR_SUBMISSION',
    VOTE_FOR_SUBMISSION_SUCCESS: 'VOTE_FOR_SUBMISSION_SUCCESS',
    VOTE_FOR_SUBMISSION_FAIL: 'VOTE_FOR_SUBMISSION_FAIL',

    INITIATE_2FA_FOR_VOTE: 'INITIATE_2FA_FOR_VOTE',
    INITIATE_2FA_FOR_VOTE_SUCCESS: 'INITIATE_2FA_FOR_VOTE_SUCCESS',
    INITIATE_2FA_FOR_VOTE_FAIL: 'INITIATE_2FA_FOR_VOTE_FAIL',

    GET_USER_VOTER_ID: 'GET_USER_VOTER_ID',
    GET_USER_VOTER_ID_SUCCESS: 'GET_USER_VOTER_ID_SUCCESS',
    GET_USER_VOTER_ID_FAIL: 'GET_USER_VOTER_ID_FAIL',

    CREATE_BOOKMARK: 'CREATE_BOOKMARK',
    CREATE_BOOKMARK_SUCCESS: 'CREATE_BOOKMARK_SUCCESS',
    CREATE_BOOKMARK_FAIL: 'CREATE_BOOKMARK_FAIL',

    CREATE_HOST: 'CREATE_HOST',
    CREATE_HOST_SUCCESS: 'CREATE_HOST_SUCCESS',
    CREATE_HOST_FAIL: 'CREATE_HOST_FAIL',

    GET_HOST: 'GET_HOST',
    GET_HOST_SUCCESS: 'GET_HOST_SUCCESS',
    GET_HOST_FAIL: 'GET_HOST_FAIL',

    GET_ALL_HOSTS: 'GET_ALL_HOSTS',
    GET_ALL_HOSTS_SUCCESS: 'GET_ALL_HOSTS_SUCCESS',
    GET_ALL_HOSTS_FAIL: 'GET_ALL_HOSTS_FAIL',

    UPDATE_HOST: 'UPDATE_HOST',
    UPDATE_HOST_SUCCESS: 'UPDATE_HOST_SUCCESS',
    UPDATE_HOST_FAIL: 'UPDATE_HOST_FAIL',

    DELETE_HOST: 'DELETE_HOST',
    DELETE_HOST_SUCCESS: 'DELETE_HOST_SUCCESS',
    DELETE_HOST_FAIL: 'DELETE_HOST_FAIL',

    CREATE_PARTNER: 'CREATE_PARTNER',
    CREATE_PARTNER_SUCCESS: 'CREATE_PARTNER_SUCCESS',
    CREATE_PARTNER_FAIL: 'CREATE_PARTNER_FAIL',

    GET_PARTNER: 'GET_PARTNER',
    GET_PARTNER_SUCCESS: 'GET_PARTNER_SUCCESS',
    GET_PARTNER_FAIL: 'GET_PARTNER_FAIL',

    GET_ALL_PARTNERS: 'GET_ALL_PARTNERS',
    GET_ALL_PARTNERS_SUCCESS: 'GET_ALL_PARTNERS_SUCCESS',
    GET_ALL_PARTNERS_FAIL: 'GET_ALL_PARTNERS_FAIL',

    UPDATE_PARTNER: 'UPDATE_PARTNER',
    UPDATE_PARTNER_SUCCESS: 'UPDATE_PARTNER_SUCCESS',
    UPDATE_PARTNER_FAIL: 'UPDATE_PARTNER_FAIL',

    DELETE_PARTNER: 'DELETE_PARTNER',
    DELETE_PARTNER_SUCCESS: 'DELETE_PARTNER_SUCCESS',
    DELETE_PARTNER_FAIL: 'DELETE_PARTNER_FAIL',

    SEARCH_HOST: 'SEARCH_HOST',
    SEARCH_HOST_SUCCESS: 'SEARCH_HOST_SUCCESS',
    SEARCH_HOST_FAIL: 'SEARCH_HOST_FAIL',

    SEARCH_PARTNER: 'SEARCH_PARTNER',
    SEARCH_PARTNER_SUCCESS: 'SEARCH_PARTNER_SUCCESS',
    SEARCH_PARTNER_FAIL: 'SEARCH_PARTNER_FAIL',

    CREATE_AWARD_CATEGORY: 'CREATE_AWARD_CATEGORY',
    CREATE_AWARD_CATEGORY_SUCCESS: 'CREATE_AWARD_CATEGORY_SUCCESS',
    CREATE_AWARD_CATEGORY_FAIL: 'CREATE_AWARD_CATEGORY_FAIL',

    DELETE_AWARD_CATEGORY: 'DELETE_AWARD_CATEGORY',
    DELETE_AWARD_CATEGORY_SUCCESS: 'DELETE_AWARD_CATEGORY_SUCCESS',
    DELETE_AWARD_CATEGORY_FAIL: 'DELETE_AWARD_CATEGORY_FAIL',

    SET_CONTEST_FOR_EDIT: 'SET_CONTEST_FOR_EDIT',

    GET_REDEEMABLE_CODE: 'GET_REDEEMABLE_CODE',
    GET_REDEEMABLE_CODE_SUCCESS: 'GET_REDEEMABLE_CODE_SUCCESS',
    GET_REDEEMABLE_CODE_FAIL: 'GET_REDEEMABLE_CODE_FAIL',

    ASSIGN_REDEEMABLE_CODE: 'ASSIGN_REDEEMABLE_CODE',
    ASSIGN_REDEEMABLE_CODE_SUCCESS: 'ASSIGN_REDEEMABLE_CODE_SUCCESS',
    ASSIGN_REDEEMABLE_CODE_FAIL: 'ASSIGN_REDEEMABLE_CODE_FAIL',

    DOWNLOAD_ASSET: 'DOWNLOAD_ASSET',
    DOWNLOAD_ASSET_SUCCESS: 'DOWNLOAD_ASSET_SUCCESS',
    DOWNLOAD_ASSET_FAIL: 'DOWNLOAD_ASSET_FAIL',

    SET_VOTING_FLOW: 'SET_VOTING_FLOW',
    SET_VOTING_2FA: 'SET_VOTING_2FA',
    SET_VOTING_2FA_STARTING_INDEX: 'SET_VOTING_2FA_STARTING_INDEX',

    GET_AWARD_CATEGORIES_FOR_CONTEST: 'GET_AWARD_CATEGORIES_FOR_CONTEST',
    GET_AWARD_CATEGORIES_FOR_CONTEST_SUCCESS: 'GET_AWARD_CATEGORIES_FOR_CONTEST_SUCCESS',
    GET_AWARD_CATEGORIES_FOR_CONTEST_FAIL: 'GET_AWARD_CATEGORIES_FOR_CONTEST_FAIL',

    GET_AWARDED_PRIZES_FOR_CONTEST: 'GET_AWARDED_PRIZES_FOR_CONTEST',
    GET_AWARDED_PRIZES_FOR_CONTEST_SUCCESS: 'GET_AWARDED_PRIZES_FOR_CONTEST_SUCCESS',
    GET_AWARDED_PRIZES_FOR_CONTEST_FAIL: 'GET_AWARDED_PRIZES_FOR_CONTEST_FAIL',

    AWARD_PRIZE: 'AWARD_PRIZE',
    AWARD_PRIZE_SUCCESS: 'AWARD_PRIZE_SUCCESS',
    AWARD_PRIZE_FAIL: 'AWARD_PRIZE_FAIL',

    SEND_WINNERS_EMAIL: 'SEND_WINNERS_EMAIL',
    SEND_WINNERS_EMAIL_SUCCESS: 'SEND_WINNERS_EMAIL_SUCCESS',
    SEND_WINNERS_EMAIL_FAIL: 'SEND_WINNERS_EMAIL_FAIL',

    SEND_CONTEST_EMAIL: 'SEND_CONTEST_EMAIL',
    SEND_CONTEST_EMAIL_SUCCESS: 'SEND_CONTEST_EMAIL_SUCCESS',
    SEND_CONTEST_EMAIL_FAIL: 'SEND_CONTEST_EMAIL_FAIL',

    SET_SUBMISSION_PROMPT: 'SET_SUBMISSION_PROMPT',

    SET_AUTO_PUBLISH_AND_SUBMIT: 'SET_AUTO_PUBLISH_AND_SUBMIT',

    GET_ALL_SUBMISSIONS: 'GET_ALL_SUBMISSIONS',
    GET_ALL_SUBMISSIONS_SUCCESS: 'GET_ALL_SUBMISSIONS_SUCCESS',
    GET_ALL_SUBMISSIONS_FAIL: 'GET_ALL_SUBMISSIONS_FAIL',

    CREATE_SCORE_CATEGORY: 'CREATE_SCORE_CATEGORY',
    CREATE_SCORE_CATEGORY_SUCCESS: 'CREATE_SCORE_CATEGORY_SUCCESS',
    CREATE_SCORE_CATEGORY_FAIL: 'CREATE_SCORE_CATEGORY_FAIL',

    DELETE_SCORE_CATEGORY: 'DELETE_SCORE_CATEGORY',
    DELETE_SCORE_CATEGORY_SUCCESS: 'DELETE_SCORE_CATEGORY_SUCCESS',
    DELETE_SCORE_CATEGORY_FAIL: 'DELETE_SCORE_CATEGORY_FAIL',

    UPDATE_SCORE_CATEGORY: 'UPDATE_SCORE_CATEGORY',
    UPDATE_SCORE_CATEGORY_SUCCESS: 'UPDATE_SCORE_CATEGORY_SUCCESS',
    UPDATE_SCORE_CATEGORY_FAIL: 'UPDATE_SCORE_CATEGORY_FAIL',

    SET_FOCUS_CAMPAIGN: 'SET_FOCUS_CAMPAIGN',

    SET_EMAIL_IMAGE: 'SET_EMAIL_IMAGE',

    RESET_VOTE: 'RESET_VOTE',

    GET_CONTEST_REFERENCES: 'GET_CONTEST_REFERNCES',
    GET_CONTEST_REFERENCES_SUCCESS: 'GET_CONTEST_REFERNCES_SUCCESS',
    GET_CONTEST_REFERENCES_FAIL: 'GET_CONTEST_REFERNCES_FAIL',

    GET_USERS_UNSUBMITTED_PROJECTS: 'GET_USERS_UNSUBMITTED_PROJECTS',
    GET_USERS_UNSUBMITTED_PROJECTS_SUCCESSS: 'GET_USERS_UNSUBMITTED_PROJECTS_SUCCESS',
    GET_USERS_UNSUBMITTED_PROJECTS_FAIL: 'GET_USERS_UNSUBMITTED_PROJECTS_FAIL',

    SET_CATEGORY_FOR_SUBMISSION: 'SET_CATEGORY_FOR_SUBMISSION',

    GET_S3_IMAGES: 'GET_S3_IMAGES',
    GET_S3_IMAGES_SUCCESS: 'GET_S3_IMAGES_SUCCESS',
    GET_S3_IMAGES_FAIL: 'GET_S3_IMAGES_FAIL',
};

export interface IGetContestPayload {
    contestId: string;
    withNavigate?: boolean;
    /** Allows us to add search=true to teh query to create activity for the search document */
    params?: string;
    /** Sets whether this will use the contest_id instead */
    isEvent?: boolean;
}

export interface IGetContestResponse {
    data: IContest;
}
export interface IGetS3ImagesResponse {
    status: string;
    data: {
        s3Images: string[];
    };
}

type TRating = 'G' | 'PG' | 'PG-13' | 'R' | 'NC-17' | 'NR' | 'NR-17';
type TVoting = 'daily' | 'weekly' | 'one-off';
type TMedia = 'video' | 'audio' | 'picture' | 'document';
type TKind = 'physical' | 'online';
export type TProjectFormat = 'act' | 'video';

type TTechnicalSpec = {
    min_duration: number;
    max_duration: number;
    media_type: TMedia;
    /** Submit vertical or horixontal videos */
    project_format: TProjectFormat;
};

export interface ICreateContestPayload {
    title: string;
    description: string;
    requirements: string[];
    /** Files to be used for campaign */
    contest_media?: string[];
    contest_video?: string;
    rating?: TRating;
    voting_required?: boolean;
    feature_image?: string;
    mobile_feature_image?: string;
    is_feature?: boolean;
    voting_type?: TVoting;
    tag?: string[];
    date_start: string;
    expiration_date: string;
    contest_phases_enabled?: boolean;
    premium?: boolean;
    submission_cost?: string;
    contest_phase_dates?: any[];
    partner?: string[];
    host?: string;
    technical_spec?: TTechnicalSpec;
    genre?: TContestGenre[];
    prize?: IContestPrize[];
    prizes?: IContestPrize[];
    jury?: IUserSummary[];
    _id?: string;
    id?: string;
    registration_required?: boolean;
    kind?: TKind;
    address?: string;
    late_entry: boolean;
    categories_enabled?: boolean;

    currency?: string;
}

export interface ICreateContestResponse extends IContest {}

export interface IContestInitialState {
    contests: IContest[];
    gettingContests: boolean;
    contestErr: any;
    recommendations: IContest[];
    gettingRecommendations: boolean;
    contestInCreation?: IContest;
    userSubmissions: ISubmission[];
    gettingSubmissions: boolean;
    selectedContest?: IContest;
    loading?: boolean;
    similarContest?: IContest[];
    userContests: IContest[];
    isSubmisisonModalOpen: boolean;
    selectedContestForSubmisison?: IContest;
    submittingProject: boolean;
    rescindingSubmission: boolean;
    initializingVote?: boolean;
    castingVote?: boolean;
    votes?: IVote[];
    voteErrMsg?: any;
    gettingBookmarks: boolean;
    userBookmarks: IBookmark[];
    creatingBookmark?: boolean;
    deletingBookmark?: boolean;
    deletingContest?: boolean;
    hosts?: IHost[];
    partners?: IPartner[];
    creatingHost?: boolean;
    creatingPartner?: boolean;
    creatingContest?: boolean;
    searchingHost?: boolean;
    searchingPartner?: boolean;
    hostSearchResult?: IHost[];
    partnerSearchResult?: IPartner[];
    selectedContestForMetrics?: IAdvancedContest;
    markedForDelete: IBookmark[];
    redeemableCodes: IReedemableCode[];
    assigningCode?: boolean;
    fetchingAssignedCodes?: boolean;
    submissionsToVoteFor?: ISubmission[];
    showVotingBooth?: boolean;
    showVoting2Fa?: boolean;
    startingIndex?: number;
    selectedContestAwardCategories: IAwardCategory[];
    selectedContestPrizeWinners: IContestAward[];
    assigningPrize?: boolean;
    gettingContestAwardCategories?: boolean;
    /**
     * The below we will use to ask users to submit a project to a contest.
     * This only happens only when they triggered create project flow from the
     * campaignSubmitter modal. This will be true when the location is submission and there
     * is an active currently selected contest for submission and also a project in active mode
     */
    promptSubmission?: boolean;
    /** When a user selects auto submit for their project, we trigger this as true */
    autoPublishAndSubmit?: boolean;
    scoreBoard: IContestSubmissionsWithVotes[];
    fetchingScoreBoard?: boolean;
    creatingScorecategory?: boolean;
    /** This contest is the one that a user has opted in to submit to */
    focusCampaign?: IContest;
    emailImage?: string;
    references?: IProject[];
    gettingReferences?: boolean;
    unsubmittedProjects: IProject[];
    gettingUnSubmittedProjects?: boolean;
    currentUnsubmittedProjectsPage?: number;
    unsubmittedProjectErrMsg?: any;
    selectedCategoryForSubmission?: string;
    gettingS3Images: boolean;
    s3Images: string[];
}

export interface IBookmark {
    details: Details;
    created_at: string;
    _id: string;
    created_by: string;
    user: string;
    __v: number;
    id: string;
}
export interface Details {
    type: string;
    model: IContest;
}
export interface ISubmitProject {
    project: string;
    contest: string;
    reedemableCode?: string;
    isHostSubmission?: boolean;
    category?: string;
}

export interface IGetContests {
    params?: string;
}

export interface IGetContestsResponse {
    data: IContest[];
    isFilitered?: boolean;
    extraDetails?: any;
}

export interface ISubmisisonModalPayload {
    open: boolean;
}

export interface IInitiate2FaForVote {
    phone_number?: string; // Making this optional as we will now pass phone number for logged in users in the backend
    submission_id: string;
    email?: string;
}

export interface IInitiate2FAResponse {
    verified: boolean;
    _id: string;
    phone_number: string;
    createdAt: string;
    updatedAt: string;
    id: string;
}

export interface ICastVote {
    /** The original number used for submisison */
    phone_number: string;
    /** The code sent to phone */
    code: string;
    submission_id: string;
}

export interface IVote {
    _id: string;
    contest: IContest;
    submission: string;
    voter: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    id: string;
}

export type TBookmarkTypes = 'Contest' | 'Project';

export type TDetails = {
    type: TBookmarkTypes;
    model: string;
};

export interface ICreateBookmark {
    details: TDetails;
}

export interface ISocials {
    instagram: string | null;
    x: string | null;
    thspian: string | null;
    tik_tok: string | null;
    snapchat: string | null;
    linkedin: string | null;
    facebook: string | null;
}

export interface ICreateHost {
    name: string;
    description?: string;
    established?: string;
    profile_picture?: string;
    socials?: ISocials;
    website?: string;
}

export interface ICreatePartner {
    name: string;
    description?: string;
    established?: string;
    profile_picture?: string;
    socials?: ISocials;
    website?: string;
}

export type TContestFilter = 'week' | 'year' | 'month';
export interface IGetUserContest {
    contestId: string;
    filter?: TContestFilter;
    start?: string;
    end?: string;
    withNavigate?: boolean;
    skipRefresh?: boolean;
}

export interface IGetUserContests {
    params?: string;
}

export interface IGetHost {
    hostId: string;
}

export interface IGetAllHosts {
    params?: string;
}

export interface IGetPartner {
    partnerId: string;
}

export interface IGetAllPartners {
    params?: string;
}

export interface ISearchPayload {
    searchTerm: string;
}

export interface ISearchHostResponse {
    result: IHost[];
    total_result_count: number;
}

export interface ISearchPartnerResponse {
    result: IPartner[];
    total_result_count: number;
}

export interface ICreateAwardCategory {
    category_title: string;
    prize: string;
    contest: string;
    year_started?: string;
    sub_category?: 'male' | 'female';
}

export interface IAwardCategory {
    category_title: string;
    prize: string;
    contest: string;
    year_started?: string;
    sub_category?: 'male' | 'female';
    _id: string;
    event?: {
        type: 'Festival' | 'Contest';
        model: string;
    };
}

export interface IAssignRedeemableCode {
    userId: string;
    contestId: string;
}

export type TRedeemableCode = {
    _id: string;
    used: boolean;
    code: string;
    expiration_date: string;
    created_by: string;
    contest: string;
    createdAt: string;
    updatedAt: string;
    used_on?: string;
    user: string;
};

export interface IGetRedeemableCodes {
    contestId: string;
    page?: number;
    limit?: number;
}

export interface IDownloadAsset {
    key: string;
}

export interface ISetVotingFlow {
    submissions: ISubmission[];
    showVotingModal?: boolean;
}

export interface IAwardPrize {
    /** Use current year */
    award_year?: string | Date;
    /** Id of the award category */
    award_category?: string;
    /** Most won */
    award_status?: AwardStatus;
    /** Project ID */
    award_project?: string | IProjectSummary;
    /** User Id */
    person_awarded?: string | UserSummary;
    /**contestId */
    contest?: string | IContest;
    feature_image?: string;
    hash?: string;
}

export interface ICreateWinnersEmail {
    contestId: string;
    is_test?: boolean;
}
export interface ISendContestEmail {
    contestId: string;
    subject?: string;
    image?: string;
    message?: string;
    testUsers?: IUserSummary[];
    type?: string[];
}
export interface IGetAllSubmissions {
    contestId: string;
    sortBy: 'total_votes' | 'createdAt';
    page?: number;
    limit?: number;
}

export interface IContestScoreResponse {
    submissions: IContestSubmissionsWithVotes[];
    details: IGetAllSubmissions;
}

export interface ICreateScoreCategory {
    category_title: string;
    tag: string[];
}

export interface IGetUnsubmittedProjects {
    contestId: string;
    sortBy?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    searchTerm?: string;
}

export interface IGetUnsubmittedProjectsResponse {
    details: IGetUnsubmittedProjects;
    results: IProject[];
    current_page: number;
    total_pages: number;
    total_items: number;
}
